import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['googlemap', 'categoryfilter', 'gardenFilter', 'partnerFilter', 'gemeindeFilter', 'generalFilterGarden', 'generalFilterPartner', 'generalFilterGemeinde', 'tooltip'];

    static values = {
        apiKey: String,
        pins: Array,
        icons: Object,
        municipalities: Object,
        municipalitiesGeojson: String
    }

    initMap() {
        const bounds = new google.maps.LatLngBounds();
        const infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, 8)
        });
        const map = new google.maps.Map(this.googlemapTarget, {
            zoom: 10,
            streetViewControl: false,
            mapTypeControl: false
        });
        this.map = map;

        this.pinsValue.forEach((pin) => {
            const marker = new google.maps.Marker({
                position: new google.maps.LatLng(pin.lat, pin.lng),
                map: map,
                icon: {
                    url: this.iconsValue[pin.type],
                    scaledSize: new google.maps.Size(40, 40),
                    anchor: new google.maps.Point(20, 20),
                }
            });
    
            // extend the bounds to include each marker's position
            bounds.extend(marker.position);
    
            // info window popup
            google.maps.event.addListener(marker, 'click', ((marker) => {
                return () => {
                    infowindow.setContent(`
                        <div class="interactivemap__popup">
                          <h3 class="interactivemap__popup__headline">${pin.headline}</h3>
                          <p class="interactivemap__popup__address">${pin.address}</p> 
                          <div class="interactivemap__popup__buttons">
                            <a class="interactivemap__popup__button" href="${pin.url}">Details</a>
                            <a class="interactivemap__popup__button" href="https://www.google.com/maps/dir/?api=1&destination=${pin.lat},${pin.lng}&travelmode=driving" target="_blank">Route</a>
                          </div>
                        </div>
                    `);
                    infowindow.open(map, marker);
                }
            })(marker));

            // add the pin's data to the marker
            marker.data = pin;

            this.markers.push(marker);
        });

        google.maps.event.addListener(map, 'zoom_changed', () => {
            const size = map.getZoom() >= 10 ? 80 : 40;
            const center = size * 0.5;

            this.markers.forEach((marker) => {
                marker.setIcon({
                    url: this.iconsValue[marker.data.type],
                    scaledSize: new google.maps.Size(size, size),
                    anchor: new google.maps.Point(center, center),
                })
            });
        });

        if (this.municipalitiesGeojsonValue) {
            map.data.loadGeoJson(this.municipalitiesGeojsonValue);
            map.data.addListener('mousemove', (event) => {
                if (event.domEvent.target.closest('[role="button"]')) {
                    this.tooltipTarget.classList.remove('show');
                } else {
                    this.tooltipTarget.style = `
                        left: ${event.domEvent.clientX}px;
                        top: ${event.domEvent.clientY}px;
                    `;

                    this.tooltipTarget.classList.add('show');

                    this.tooltipTarget.querySelector('.interactivemap__tooltip__inner').innerText = event.feature.Fg.PG;
                }
            });

            map.data.addListener('mouseout', () => {
                this.tooltipTarget.classList.remove('show');
            });

            map.data.setStyle(() => {
                return {
                    visible: false
                }
            });
        }

        // Set center to Niederösterreich
        map.setCenter(new google.maps.LatLng(48.27138, 15.75188));
        map.setZoom(9);

        this.applyFilter();

        this.element.classList.add('init');
    }

    getFeatureStyle(category) {
        if (248 === category) {
            return {
                visible: true,
                strokeColor: '#95C11F',
                strokeOpacity: 1.0,
                strokeWeight: 1.0,
                fillColor: '#95C11F',
                fillOpacity: 0.5,
            };  
        }

        if (249 === category) {
            return {
                visible: true,
                strokeColor: '#FFCC00',
                strokeOpacity: 1.0,
                strokeWeight: 1.0,
                fillColor: '#FFCC00',
                fillOpacity: 0.5,
            };
        }

        return {
            visible: true,
            strokeColor: '#006930',
            strokeOpacity: 1.0,
            strokeWeight: 1.0,
            fillColor: '#006930',
            fillOpacity: 0.5,
        };
    }

    applyFilter() {
        this.closeFilter();

        const schaugartenFilter = this.getFormData(this.gardenFilterTarget);
        const partnerFilter = this.getFormData(this.partnerFilterTarget);
        const gemeindeFilter = this.getFormData(this.gemeindeFilterTarget);

        const hideGardens = !this.generalFilterGardenTarget.checked;
        const hidePartner = !this.generalFilterPartnerTarget.checked;
        const hideGemeinde = !this.generalFilterGemeindeTarget.checked;

        this.markers.forEach((marker) => {
            marker.setVisible(true);

            if ('schaugarten' === marker.data.type) {
                if (hideGardens) {
                    marker.setVisible(false);
                } else {
                    if (schaugartenFilter['_gc[]'] ?? null) {
                        const filtered = schaugartenFilter['_gc[]'].map(v => parseInt(v, 10)).filter(value => marker.data.categoryIds.includes(value));
        
                        if (0 === filtered.length) {
                            marker.setVisible(false);
                        }
                    }
        
                    if (schaugartenFilter['_garden-accessible'] ?? null) {
                        if (!marker.data.accessible) {
                            marker.setVisible(false);
                        }
                    }
                }
            }

            if ('partner' === marker.data.type) {
                if (hidePartner) {
                    marker.setVisible(false);
                } else {
                    if (partnerFilter['_pc[]'] ?? null) {
                        const filtered = partnerFilter['_pc[]'].map(v => parseInt(v, 10)).filter(value => !marker.data.categoryIds.includes(value));

                        if (filtered.length > 0) {
                            marker.setVisible(false);
                        }
                    }
                }
            }
        });

        if ((gemeindeFilter['_mc'] ?? null) && gemeindeFilter['_mc'].length > 0) {
            this.map.data.setStyle((feature) => {
                if (hideGemeinde) {
                    return {
                        visible: false
                    }
                } else {
                    if (typeof feature.Fg === 'undefined') {
                        return {
                            visible: false
                        }
                    }

                    const key = typeof feature.Fg.GKZ !== 'undefined' ? feature.Fg.GKZ : feature.Fg.iso;
    
                    if (typeof this.municipalitiesValue[key] === 'undefined') {
                        return {
                            visible: false
                        }
                    }
    
                    const data = this.municipalitiesValue[key];
                    const filtered = gemeindeFilter['_mc'].map(v => parseInt(v, 10)).filter(value => data.categoryIds.includes(value));
    
                    if (0 === filtered.length) {
                        return {
                            visible: false
                        }
                    }
        
                    return this.getFeatureStyle(parseInt(gemeindeFilter['_mc'][0], 10));
                }
                
            });
        }
    }

    getFormData(form) {
        const formData = new FormData(form);

        return Object.fromEntries(
            Array.from(formData.keys()).map(key => [
                key, formData.getAll(key)
            ])
        );
    }

    closeFilter() {
        this.element.classList.remove('show-filter');

        this.categoryfilterTargets.forEach((element) => {
            element.classList.remove('open');
        });
    }

    showFilter() {
        this.element.classList.add('show-filter');
    }

    toggleCategories(e) {
        const parent = e.currentTarget.closest('.interactivemap__filter-section-categories');

        if (null !== parent) {
            this.categoryfilterTargets.forEach((element) => {
                if (element !== parent) {
                    element.classList.remove('open');
                }
            });

            parent.classList.toggle('open');
        }
    }

    documentClick(e) {
        this.categoryfilterTargets.forEach((element) => {
            if (!element.contains(e.target)) {
                element.classList.remove('open');
            }
        });
    }

    uncheckRadio(e) {
        const input = document.getElementById(e.currentTarget.getAttribute('for'));

        if (null !== input && input.checked) {
            e.preventDefault();
            input.checked = false;
        }
    }

    connect() {
        this.markers = [];
        this.map = null;
        const apiKey = this.apiKeyValue;
        const element = this.googlemapTarget;

        window.googleMapsCallback = () => {
            window.dispatchEvent(new Event('google-maps-callback'));
        }

        const cookiebarInit = () => {
            cookiebar.addModule(7, function() {
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.defer=true; g.src='https://maps.googleapis.com/maps/api/js?key='+apiKey+'&callback=googleMapsCallback'; s.parentNode.insertBefore(g,s);
            }, {
                selector: element,
                message: 'Diese Karte verwendet Google Maps.',
                button: {                       
                    show: true,
                    text: 'Google Maps zulassen',
                    classes: 'cc-btn'
                }
            });
        };

        if (typeof window.cookiebar === 'undefined') {
            document.addEventListener('DOMContentLoaded', cookiebarInit);
        } else {
            cookiebarInit();
        }
    }
}
